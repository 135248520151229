function isMobile() {
  const menuMobile = document.querySelector('.nav__menuMobile');
  document
    .querySelector('.nav__menu input')
    .addEventListener('change', function () {
      if (window.matchMedia('(max-width: 880px)').matches) {
        if (this.checked) {
          menuMobile.classList.add('active');
        } else {
          menuMobile.classList.remove('active');
        }
      }
    });

  if (window.matchMedia('(min-width: 880px)').matches) {
    menuMobile.classList.remove('active');
  }
}

document.addEventListener('DOMContentLoaded', isMobile);
window.addEventListener('resize', isMobile);
